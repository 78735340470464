"use strict";
require("@babel/polyfill");

// Luxon
import { DateTime } from 'luxon';
window.DateTime = DateTime;

// EXIF-js
import { EXIF } from 'exif-js';
window.EXIF = EXIF;

// Alpinejs
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import persist from '@alpinejs/persist';
import focus from '@alpinejs/focus';
 
Alpine.plugin(collapse);
Alpine.plugin(persist);
Alpine.plugin(focus);

window.Alpine = Alpine;
window.Alpine.start();

